#main-window {
  margin: auto;
  margin-top: 1rem;
  width: 90%;
  animation: showUpAnim 0.8s;
}

.window-body.main {
  width: 90%;
  padding: auto;
  margin: auto;
}

@keyframes showUpAnim {
  from {
    opacity: 10%;
  }
  to {
    opacity: 100%;
  }
}

ul {
  margin: 0;
  margin-bottom: 1rem;
  list-style-type: none;
  padding: 0;
}

.fit-picture {
  max-width: 100%;
  max-height: 100%;
  width: 300px;
}
