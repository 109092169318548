.booter-text {
  color: white;
  -webkit-font-smoothing: none;
  font-family: "Pixelated MS Sans Serif", Arial;
  font-weight: 700;
  font-size: 14px;
}

#showMe {
  animation: cssAnimation 0.8s infinite;
  display: inline;
}

@keyframes cssAnimation {
  50% {
    opacity: 0;
  }
}
